import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
 /*  {
    path: '/',
    name: 'Inicio',
    component: Inicio
  }, */
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
