export const imagens =
{
  carrossel_01: [
    /* Tre */
    {
      id: 'mp2rewfwbiuz',
      name: 'Tre | One Page',
      link: 'https://tre-art-4b66f.web.app/',
      paths: [
        {
          img: 'tre/mobile-tre-studio-design.jpg',
          alt: 'Versão mockup mobile do website dos studios Tre'
        },
        {
          img: 'tre/studio-tre.jpg',
          alt: 'Versão mockup dektop do website dos studios Tre'
        },
        {
          img: 'tre/website-tre-studio-design.jpg',
          alt: 'Versão desktop do website dos studios Tre'
        },
      ],
      description: "A Tre Comunicação é um estúdio de design. Eu, mais uma equipe de 3 pessoas, trabalhamos em uma nova prosta para o website da empresa. No projeto, além da minha participação no design, assumi total responsabilidade pela programação e hospedagem dos códigos no domínio da Tre.",
      thumb: {
        img: 'tre/thumb-website-tre-studio-design.jpg',
        alt: 'Onepage - Empresa Tre Designs | Vuejs html css'
      },
      github: 'https://github.com/MateusDuraessantos/tre',
      ano: 'Fevereiro, 2023',
      link: 'https://tre.art.br/',
    },
    {
      /* MUSIC */
      id: 'molaergmplreg',
      name: 'Music SP | One page',
      paths: [
        {
          img: 'music/mockup-apple-imac.jpg',
          alt: 'Mockup desktop e mobile do website Music SP'
        },
        {
          img: 'music/music_sp-webiste.jpg',
          alt: 'Website Music SP One Page'
        },
      ],
      description: 'Nesse projeto, focado na divulgação projetos músical (bandas, orquestras, artistas individuais), junto a uma equipe, participei da criação do design visual e usabilidade/experiência do usuário. Utilizando como ferramenta de prototipação o Figma.',
      categoria: 'website',
      ano: 'Maio, 2020',
      thumb: {
        img: 'music/thumb-music_sp-webiste.jpg',
        alt: 'Website Music SP One Page'
      },
    },
    // TCC
    {
      id: 'dfrejmwopqfremn',
      name: 'Redesign website do curso de Design Mackenzie',
      paths: [
        {
          img: 'tcc/webiste_mackenzie_curso_de_design.jpg',
          alt: 'Meu trabalho de conclusão de curso mockup'
        },
        {
          img: 'tcc/pagina_inicial_do_projeto.jpg',
          alt: 'Website do meu TCC'
        },
        {
          img: 'tcc/arvore_de_navegacao_proposta.jpg',
          alt: 'Árvore de navegação do website desenvolvido no TCC'
        },
      ],
      description: `
        &emsp;Nesse projeto, desenvolvido para o website do curso de Design da Universidade Presbiteriana Mackenzie, busquei aprimorar a experiência do usuário. Propus uma nova abordagem de UI (Interface do Usuário) e UX (Experiência do Usuário), utilizando a metodologia de Design HCD (Design Centrado no Humano) em conjunto com "Os 5 Elementos da Experiência do Usuário" como guias principais.
        <br>&emsp;O projeto, que levou cerca de 9 meses para ser concluído, incluiu pesquisas de campo aprofundadas.
        <br>&emsp;A protipação foi realizada no Figma, e a para implementação utilizou tecnologias como HTML, CSS, Javascript, VueJs, Firebase, GitHub e Element Plus.
      `,
      ano: 'Setembro, 2022',
      thumb: {
        img: 'tcc/thumb-pagina_inicial_do_projeto.jpg',
        alt: 'Website trabalho de conclusão de curso Mackenzie'
       },
      link: 'https://mackenzie-tcc.web.app/#/',
      github: 'https://github.com/MateusDuraessantos/TCC',
    },
    // Teclakey
    {
      id: 'zmgvpregujirew',
      name: 'TeclaKey | One Page',
      paths: [
        {
          img: 'teclakey/mockup-site-studios-design-teclakey.jpg',
          alt: 'Mockup da versão mobile do website TeclaKey'
        },
        {
          img: 'teclakey/teclakey-webiste-de-design.jpg',
          alt: 'Versão desktop do website TeclaKey'
        },
        {
          img: 'teclakey/teclakey.gif',
          alt: 'Gif com animação do website mockup'
        },
      ],
      description: 'TeclaKey é um estúdio de design. Na criação do website, em colaboração com uma equipe de três pessoas, coordenei o desenvolvimento da identidade visual. Além disso, fui responsável pelo frontend, pela responsividade, pela implementação no domínio e pela otimização dos conteúdos para a web.',
      ano: 'Maio, 2022',
      thumb: {
        img: 'teclakey/thumb-teclakey-webiste-de-design.jpg',
        alt: 'Website teclakey - UX/UI - Firebase',
      },
      link: 'https://tecla-key.web.app/',
      github: 'https://github.com/MateusDuraessantos/teclakeygit',
    },
    {
      id: 'gtrnieogvreoifg',
      name: 'Data Machina | Front End',
      link: 'https://data-machina.web.app/',
      paths: [
        {
          img: 'datamachina/datamachina.jpg',
          alt: 'Mockup da versão mobile do website DataMachina'
        },
        {
          img: 'datamachina/design_system_mobile_instagram.jpg',
          alt: 'Mockup da Versão mobile do design system da empresa DataMachina'
        },
        {
          img: 'datamachina/datamachina_website_onepage.jpg',
          alt: 'Website da empresa DataMachina'
        },
      ],
      description: 'Nesse projeto, com o design (para desktop) e conteúdos já desenvolvido préviamente por outros colaboradores, fiquei encarregado de desenvolver toda a etapa de HTML e CSS e torná-lo responsivo para dispositivos mobile.',
      thumb: {
        img: 'datamachina/thumb-datamachina_website_onepage.jpg',
        alt: 'Data Machina / Projeto frontend css e html',
      },
      ano: 'Dezembro, 2021',
      link: 'https://data-machina.web.app/',
    },
    /* TRANCENDEMOS */
   /* {
      id: 'mjykjmyt5hj',
      name: 'Transcendemos',
      paths: [
        {
          img: 'transcendemos/transcendemos-consultorias-palestras.png',
          alt: 'Transcendemos Consultorias'
        },

      ],
      description: 'Nesse projeto, trabalhei no design do projeto. Após a finalização do desing, o projeto foi colocado online por meio da plataforma WordPress.',
      thumb: {
        img: 'transcendemos/transcendemos-consultorias-palestras.png',
        alt: 'Webiste da Transcendemos',
      },
    }, */
      /* MAPALYTICS */
    {
      id: 'hjtryjytrsw',
      name: 'Mapalytics',
      paths: [
        {
          img: 'mapalytics/mapalytics.jpg',
          alt: 'mapalytics Consultorias'
        },
      ],
      description: 'Mapalytics é um painel web da Data Machina de geomarketing. Junto a uma equipe de designers, os quais desenvolveram o desing do website para divulgação do painel, fiquei responsável por colocar o website de pé, criando todos os códigos. Esse foi o resultado final!',
      thumb: {
        img: 'mapalytics/thumb-mapalytics.jpg',
        alt: 'Webiste da mapalytics',
      },
      ano: 'Março, 2024',
      link: 'https://mapalytics.com.br/'
    },
    /* BLUEWAVE */
    {
      id: 'fewtgt54432tr2',
      name: 'Bluewave',
      paths: [
        {
          img: 'bluewave/bluewave.jpg',
          alt: 'bluewave Consultorias'
        },
      ],
      description: 'No projeto, fui responsável pelo design, desenvolvendo a versão desktop no Figma.',
      ano: 'Agosto, 2024',
      thumb: {
        img: 'bluewave/bluewave.jpg',
        alt: 'Webiste da BlueWave',
      },
    },
    /* CAJUINA */
    {
      id: 'mgrwmplkfgfewplo,',
      name: 'Cajuina - Front End',
      github: 'https://github.com/MateusDuraessantos/LP-Cajuina',
      link: 'https://lp-cajuina.web.app/',
      paths: [
        {
          img: 'cajuina/website.jpg',
          alt: 'Website Cajuina',
        },
      ],
      thumb: {
        img: 'cajuina/thumb-website-cajuina.jpg',
        alt: 'Website Cajuina'
      },
      ano: 'Novembro, 2023',
      description: 'Nesse projeto, desenvolvi todos os códigos para construção do website e aplicando responsividade para dispositivos móveis.',
    },
    /* CAJU */
    {
      id: 'mvrgwpemgrpoklewgre',
      name: 'Caju | Front End',
      link: 'https://caju-multi-beneficios-visa.web.app/',
      github: 'https://github.com/MateusDuraessantos/caju-multi-beneficios',
      paths: [
        {
          img: 'caju/multibeneficios.svg',
          alt: 'Website Caju Visa',
        },
      ],
      thumb: {
        img: 'caju/thumb-multibeneficios.jpg',
        alt: 'Website Caju Visa'
      },
      ano: 'Maio, 2024',
      description: 'Nesse projeto, com design desenvolvido pela equipe da Caju, fiquei responsável por desenvolver os códigos da página e deixa-lá responsiva para dispositivos móveis.',
    },
  ],
  carrossel_02: [
    {
      id: 'omikgreoijnuwefrg',
      name: 'Mesa para computadores Desktop | Modelagem',
      paths: [
        {
          img: 'mesa/mesa-de-pinus-gamer.jpg',
          alt: 'Mesa de madeira para computador no fundo preto'
        },
        {
          img: 'mesa/1.jpg',
          alt: 'Quina da mesa'
        },
        {
          img: 'mesa/estrutura-mesa-de-pinus-gamer.jpg',
          alt: 'Extrutura do pé da mesa'
        },
        {
          img: 'mesa/estrutura-mesa-de-madeira.jpg',
          alt: 'Extrutura metalica da mesa em 3d'
        },
        {
          img: 'mesa/mesa-gamer-preta.jpg',
          alt: 'Mesa na versão preta'
        },
        {
          img: 'mesa/5.jpg',
          alt: 'Mesa de madeira para computador no fundo branco'
        },
      ],
      description: 'Nesse projeto, eu criei um modelo 3D de uma mesa que fosse ergonômica para computadores desktop. Utilizei para prototipação, modelagem e renderização, o Cinema 4D. Além disso, a mesa também saiu do papel e foi produzido em escala real, para uso doméstico.',
      thumb: {
        white: 'mesa/mesa-gamer',
        alt: 'Mesa gamer/escritório',
      },
      class: 'mesa'
    },
    {
      id: 'qwrepotiurweq',
      name: 'Pingente Butterfly',
      paths: [
        {
          img: 'butterfly/butterfly-black.jpg',
          alt: 'Pingente borboleta na versão transparente e dourado'
        },
        {
          img: 'butterfly/butterfly-white.jpg',
          alt: 'Modelo usando o colar'
        },
        {
          img: 'butterfly/colar-vermelho-3d.jpg',
          alt: 'Pingente na versão vermelha'
        },
        {
          img: 'butterfly/colar-dourado-de-borboleta.jpg',
          alt: 'Pingente borboleta na versão transparente e dourado'
        },
        {
          img: 'butterfly/colar-3d-modelagem.png',
          alt: 'Colar de borboleta modelado em 3D'
        },
      ],
      description: 'Nesse projeto de estudo, eu desenvolvi um modelo 3D de pingente, utilizando como ferramenta de modelagem e renderização o Cinema 4D.',
      thumb: {
        white: 'butterfly/butterfly',
        alt: 'Colar feminino borboleta',
      },
      class: 'butterfly'
    },
    {
      id: 'asdkljghsadfkg',
      name: 'Mun | Arandela escamoteável',
      alt: 'Luminária arandela, 3d Modelagem cinema 4D',
      paths: [
        {
          img: 'mun/mun_arandela_escamoteavel.jpg',
          alt: 'Arandela escamoteável fechada'
        },
        {
          img: 'mun/mun-arandela-black.jpg',
          alt: 'Arandela escamoteável fechada'
        },
        {
          img: 'mun/mun_visao_interna.jpg',
          alt: 'Arandela escamoteável aberta'
        },
        {
          img: 'mun/mun_visao_interna_escamoteavel.jpg',
          alt: 'Arandela escamoteável aberta'
        },
        {
          img: 'mun/mun_tag.jpg',
          alt: 'Tag com o nome da arandela: Mun'
        },
        {
          img: 'mun/mun_Iluminacao.jpg',
          alt: 'Todas as possibilidades de abertura da arandela'
        },
        {
          img: 'mun/mun_area_de_iluminacao.jpg',
          alt: 'Imagens das fases da lua'
        },
      ],
      thumb: {
        white: 'mun/mun-arandela',
        alt: 'Arandela escamoteável'
      },
      description: 'Mun é uma arandela escamotiável. O projeto foi desenvolvido por mim, junto a uma equipe de 3 pessoas. Buscamos baser-se em elementos da cultura oriental e nas fases da lua. No projeto, fiquei 100% encarregado da prototipação de um modelog físico, modelagem 3D e renderizações.',
    },
  ],
}